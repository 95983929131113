<template>
	<div>
		<fdk-cart class="product__actions" ref="cart">
			<template slot-scope="cart">
				<button style="display: none" @click="handleReload(cart)">
					Reload
				</button>
			</template>
		</fdk-cart>
		<cart.newProduct> </cart.newProduct>

		<div v-if="!this.loading" data-v-29da214c class="nav-container">
			<!--Do Not Add This Div-->
			<div v-if="rewardsPoints !== 0" class="range-wrap">
				<div class="engage-checkbox-container">
					<input
						v-model="engageCheckbox"
						type="checkbox"
						@click="handleEngageCheckbox"
						:disabled="disableCheckbox"
					/>
				</div>
				<div class="total-points">
					{{ this.textDescription }}
				</div>
			</div>
			<div class="points-discount">
				{{ this.discountTextDescription }}
			</div>
			<div v-if="possibleEarnLoyaltyPoints !== 0" class="points-discount">
				<img src="./assets/images/coins.svg" alt="Coins" width="40" />
				{{ this.earnPointsTextDescription }}
			</div>
			<!-- <div class="avil-points">
        <img src="./assets/images/coins.svg" alt="Coins" width="40" />
        <div class="avil-points-info">
          <div class="additional-points">
            Save additional ₹{{ this.discountPoints ? this.discountPoints : 0 }}
          </div>
        </div>
      </div> -->
		</div>
	</div>
</template>

<script>
import {
	addItemsInCart,
	applyOrRemoveCartInjection,
	getUserReferralInfo,
	getUserTransactionHistory,
	removeItemsInCart,
	rewardDetailsOfUser,
	updateItemsInCart,
} from '../../services/cart.service';

// @TODO HAVE TO REMOVE ALL THE LAGS ->

export default {
	name: 'rewards-view',
	data() {
		return {
			// check box check and uncheck state
			// You know this is only adjustment comment for my mistakes in this world of fynd realm
			engageCheckbox: false,

			user_info: undefined,
			// total discount points from engage service
			engageLoyaltyPoints: 0,
			// total applicable discount points from engage service
			applicableLoyaltyPoints: 0,

			possibleEarnLoyaltyPoints: 0,

			applicable: 0,
			disableCheckbox: false,

			textDescription: `Total 0 Engage Points`,
			discountTextDescription: `0 Engage points redeemable on this order`,
			earnPointsTextDescription: `0 Engage points earn rewards`,
			transaction_id: undefined,
			items: [],
			isEarnPointsApplicable: false,
		};
	},

	async mounted() {
		/**
		 * ON mount
		 **/
		// Subscribe to the "cart.newProduct" event
		//window.FPI.event.on('cart.newProduct', this.handleUpdateCartEvent());

		window.FPI.event.on('cart.update', async () => {
			console.log(`Cart Item updated`);
			await this.fetchUserEngagePointsOnCart();
		});

		window.FPI.event.on('cart.remove', async () => {
			console.log(`Cart Item Removed`);
			await this.fetchUserEngagePointsOnCart();
		});

		await this.fetchUserEngagePointsOnCart();
	},

	methods: {
		getUrlParams() {
			// Get the full URL
			const url = window.location.href;

			// Create a URL object
			const urlObj = new URL(url);

			// Get the search parameters
			const params = new URLSearchParams(urlObj.search);

			// Fetch the parameters
			const cartId = params.get('cart_id') || params.get('id');
			// @TODO parse the boolean
			const buyNow =
				params.get('buy_now') === 'true' || params.get('buyNow' === 'true')
					? true
					: false; // Convert the string to a boolean

			// Return the parameters as an object
			return {
				cartId,
				buyNow,
			};
		},

		showTooltip() {
			this.isHovered = true;
		},
		hideTooltip() {
			this.isHovered = false;
		},

		async getUserTxn() {
			await getUserTransactionHistory();
		},

		/**
		 * @function handleUpdateCartEvent
		 * @description This function orchestrates the cart update event, determining whether to apply or remove cart injection points based on the engageCheckbox status and if discount points are available.
		 *
		 * The plan:
		 * 1. Reload the current state.
		 * 2. Check if engage points should be applied or removed based on engageCheckbox status and discountPoints.
		 * 3. Apply the order discount.
		 * 4. Reload the state.
		 */

		getQueryParams(url) {
			console.log(`before url coming : ${url}`);

			const urlObj = new URL(url);

			console.log(`url coming : ${urlObj}`);
			const params = {};

			urlObj.searchParams.forEach((value, key) => {
				params[key] = value;
			});

			console.log(`final params : ${params}`);
			return params;
		},

		async handleUpdateCartEvent() {
			try {
				this.disableCheckbox = true;

				// Check if discountPoints are available before applying or removing cart injection
				if (this.applicableLoyaltyPoints > 0 && this.engageCheckbox) {
					// Apply or remove cart injection based on engageCheckbox status
					const action = this.engageCheckbox ? 'Applied' : 'Removed';
					const queryData = this.getQueryParams(window.location.href());
					console.log(`query data : `, queryData);

					const response = await this.applyOrRemoveCartInjection(
						this.engageCheckbox,
						queryData
					);
					console.log(`Cart injection ${action}: ${response}`);
				} else {
					this.engageCheckbox = false;
				}
			} catch (error) {
				console.error('Error handling update cart event:', error);
			} finally {
				this.disableCheckbox = false;
				await this.handleReload();
			}
		},

		async getUserReferData() {
			await getUserReferralInfo();
		},
		/**
		 * @function applyOrRemoveCartInjection
		 * @description We execute a critical operation: applying or removing cart injection points based on provided status.
		 *
		 * @param {boolean} status - The current status affecting cart points. True or false, the state changes everything.
		 *
		 * @returns {Promise<void>} - A promise that resolves once the cart injection points have been processed.
		 *
		 * The plan:
		 * 1. Extract cartId and buyNow parameters from the URL using getUrlParams.
		 * 2. Prepare a payload with the status points.
		 * 3. If a cartId is present, it becomes part of the payload. No cartId? No problem. We proceed without it.
		 * 4. Call applyOrRemoveCartInjection with the payload, altering the cart points as required.
		 *
		 * Note: Our actions remain silent but effective. The cart_id is a ghost in the machine, present only when needed.
		 */
		async applyOrRemoveCartInjection(status, query) {
			const paramsData = this.getUrlParams();
			console.log(`Params data coming for the data : `, paramsData);

			const data = query;
			console.log('Query', data);

			let payload = {
				points: status,
			};

			// If cartId exists, include it in the payload
			if (paramsData && paramsData.cartId) {
				payload.cart_id = paramsData.cartId;
			}

			// If buyNow exists, include it in the payload
			if (paramsData && paramsData.buyNow) {
				payload.b = paramsData.buyNow;
			}

			// Execute the applyOrRemoveCartInjection function with the payload
			const engagePoints = await applyOrRemoveCartInjection(payload);
			console.log(`core cart engage points `, engagePoints);
			console.log(engagePoints);
		},

		async updateCartItems() {
			updateItemsInCart();
		},
		async addItemsInCart() {
			addItemsInCart();
		},
		async removeItemsFromCart() {
			removeItemsInCart();
		},

		/**
		 * This function fetches the user's engage points and merges them with the Fynd cart details.
		 * It prepares the necessary payload and handles the engage service response.
		 *
		 * @param {Object} user - The user object containing user details.
		 * @param {string} company_id - The company ID.
		 * @param {string} application_id - The application ID.
		 * @param {string} application_token - The application token.
		 * @param {string} cookies - The cookies string.
		 * @param {boolean} engageServiceActiveStatus - The status of the engage service.
		 * @param {Object} query_data - The query data containing cart details.
		 * @returns {Object} - The final response with merged engage points and cart details.
		 * @throws {Error} - Throws an error if the process fails.
		 */
		async fetchUserEngagePointsOnCart() {
			console.log(`fetchUserEngagePointsOnCart fetching method called `);
			const { cartId, buyNow } = this.getUrlParams();

			// Constructing the payload for the API call
			// If the cartId exists, it will be dynamically added to the payload.
			const payload = {
				b: true, // BuyNow flag, a binary decision.
				i: true,
				buy_now: buyNow, // An enigmatic boolean, always true, signifying intent.
				...(cartId && { cart_id: cartId }), // Inject cart_id into the payload if present. Optional but crucial.
			};

			try {
				// The moment of truth: Invoke the rewardDetailsOfUser API
				// Await the response, like a chess player anticipating the opponent's next move.
				const coreCartWithEngagePointsResponse = await rewardDetailsOfUser(
					payload
				);

				console.log(
					`Core Cart with engage points : `,
					coreCartWithEngagePointsResponse
				);

				// Check if our response is valid and contains what we need
				if (coreCartWithEngagePointsResponse?.status === 200) {
					const { data } = coreCartWithEngagePointsResponse;
					const loyaltyPoints = data?.breakup_values?.loyalty_points;

					// Deep dive into the data, navigating through nested layers
					if (loyaltyPoints) {
						// The ultimate treasure: loyalty points
						this.engageLoyaltyPoints = loyaltyPoints.total || 0; // The total engage points, a key metric in our journey.
						this.applicableLoyaltyPoints = loyaltyPoints.applicable || 0; // Applicable points, our immediate asset.
						this.possibleEarnLoyaltyPoints = loyaltyPoints.earnPoints || 0;

						// Decision point: Enable or disable the checkbox based on applicable points
						this.disableCheckbox = this.applicableLoyaltyPoints <= 0;

						// Set engageCheckbox and discountTextDescription based on is_applied
						this.engageCheckbox =
							loyaltyPoints?.is_applied && loyaltyPoints?.is_applied !== ''
								? true
								: false;
						if (this.engageCheckbox) {
							this.discountTextDescription = `${Math.floor(
								this.applicableLoyaltyPoints
							)} Engage Points applied on this order`;
						} else {
							this.discountTextDescription = `${Math.floor(
								this.applicableLoyaltyPoints
							)} Engage Points redeemable on this order`;
						}

						// Craft the narrative
						this.textDescription = `Total ${Math.floor(
							this.engageLoyaltyPoints
						)} Engage Points`;
						this.earnPointsTextDescription = `${Math.floor(
							this.possibleEarnLoyaltyPoints
						)} Engage points earn rewards`;
					} else {
						// In the absence of loyalty points, log an error, and disable the checkbox
						console.error('Loyalty points data is missing.'); // Log the anomaly.
						this.disableCheckbox = true; // Disable the checkbox, a safety mechanism.
					}
				} else {
					// In case of a failed API response, raise an error and disable the checkbox
					throw new Error('Failed to fetch engage points'); // Sound the alarm.
				}
			} catch (error) {
				// Catch any unforeseen errors during the API call
				console.error(
					error.message || 'An error occurred while fetching engage points.'
				); // Log the error in the system.
				this.disableCheckbox = true; // Engage the safety switch.
			}
		},

		async handleReload() {
			if (this.$refs['cart']) {
				await this.$refs['cart'].getCart();
			} else {
				console.log(`refs cart value not found`);
			}
		},

		async handleEngageCheckbox() {
			try {
				console.log('Points', this.engageCheckbox);
				this.disableCheckbox = true;
				await this.handleReload();
				await this.applyOrRemoveCartInjection(this.engageCheckbox);
				await this.handleReload();
				this.disableCheckbox = false;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<!-- Height 100% removed -->
<style lang="less">
:root,
::before,
::after {
	--imageRadius: 24px;
	--buttonRadius: 4px;
	--productImgAspectRatio: 0.8;
	--headerHeight: 79px;
	--buttonPrimaryL1: #35596c;
	--buttonPrimaryL3: #9aacb5;
	--buttonLinkL1: #77515a;
	--buttonLinkL2: #997c83;
	--themeAccentD1: #988487;
	--themeAccentD2: #726365;
	--themeAccentD3: #4c4244;
	--themeAccentD4: #262122;
	--themeAccentD5: #000000;
	--themeAccentL1: #cbb7ba;
	--themeAccentL2: #d8c9cb;
	--themeAccentL3: #e5dbdd;
	--themeAccentL4: #f2edee;
	--themeAccentL5: #ffffff;
}

html,
body {
	margin: 0px !important;
	padding: 0px !important;
}

#engage-app {
	font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0 auto;
	height: inherit;
}

.cs-strong {
	font-weight: 800;
}

.cs-dis-flex {
	display: flex;
}

.engage-cs-lm-5 {
	margin-left: 5px;
}

.nav-container {
	padding: 15px 25px 15px 25px !important;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
}

.avil-points {
	display: flex;
	gap: 12px;
	line-height: 1.35;
	align-items: center;
}

.range-slider {
	flex-grow: 1;
	margin-top: 5px;
	position: relative;
}

.points-discount {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;
	font-size: 12px;
	font-weight: 600;
}

.points-discount img {
	margin-right: 10px;
}

.earn-points-text {
	line-height: 1.5;
	font-weight: 600;
}

.total-points {
	padding-bottom: 2px;
	font-weight: 600;
	font-size: 14px;
}

.additional-points {
	font-size: 12px;
	opacity: 0.6;
}

.range-wrap {
	display: flex;
	align-items: center;
	gap: 12px;
	padding-top: 12px;
}

.range-wrap .reward-link {
	color: var(--accentColor);
	font-weight: 700;
	cursor: pointer;
	text-decoration: underline;
	margin-top: 0;
}

.range-wrap .reward-link {
	margin-top: 0;
}

.range-slider {
	flex-grow: 1;
	margin-top: 5px;
	-webkit-appearance: none;
	position: relative;
}

.range-slider:hover #tooltip span {
	opacity: 1;
	visibility: visible;
	transform: translateX(-50%) translateY(-7px) scale(1);
}

.tooltip {
	background-color: var(--accentColor);
	position: absolute;
	color: #ffffff;
	border-radius: 5px;
	padding: 5px;
	text-align: center;
	font-size: 12px;
	min-width: 30px;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
}

.range-controller {
	-webkit-appearance: none;
	width: 100%;
	background: none;
	padding-bottom: 25px;
}

.range-controller:focus {
	outline: none;
}

.range-controller::before,
.range-controller::after {
	position: absolute;
	bottom: 0;
	line-height: 1;
	padding: 3px 5px;
	background-color: rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	font-size: 12px;
}

.range-controller::before {
	left: 0;
	content: attr(min);
}

.range-controller::after {
	right: 0;
	content: attr(max);
}

.range-controller::-webkit-slider-runnable-track {
	width: 100%;
	height: 10px;
	cursor: pointer;
	animate: 0.2s;
	background: linear-gradient(
		90deg,
		var(--accentColor) var(--range-progress),
		#dee4ec var(--range-progress)
	) !important;
	border-radius: 1rem;
}

.range-controller::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 4px solid var(--accentColor);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	background: #ffffff;
	cursor: pointer;
	height: 20px;
	width: 20px;
	transform: translateY(calc(-50% + 5px));
}

.cs-vt-center {
	display: flex;
	align-items: center;
	align-content: center;
}

.rectangle-box {
	/* Adjust the width as needed */
	padding: 2px;
	/* Adjust padding as needed */
	background-color: #ffffff;
	/* Change the color as desired */
	border-radius: 5px;
	/* Adjust the border radius for slightly curved corners */
	box-shadow: 2px 2px 5px rgba(131, 130, 130, 0.3);
	/* Optional: Add shadow */
}

.engage-checkbox-container {
	margin-left: 10px;
	/* Add margin for spacing */
	/* Other styles for checkbox container */
}
</style>
